import React from "react"
import Img from "gatsby-image"

const ReviewImage = ({ image }) => {
  const { review_image } = image.elements
  return (
    <Img
      fluid={review_image.value[0].fluid}
      alt={review_image.value[0].description}
      className="review-image"
    />
  )
}
export default ReviewImage
