import React from "react"
import { Link } from "gatsby"
import * as styles from "./blog.module.scss"
const BlogHero = ({ title, published, slug, category, author }) => {
  return (
    <section className={styles.hero}>
      <h1>{title}</h1>
      <p>
        {published} -{" "}
        <Link className="category__link" to={`/blog/categories/${slug}`}>
          {category}
        </Link>
      </p>
      {author ? <p>Author - {author}</p> : undefined}
    </section>
  )
}

export default BlogHero
