import React from "react"
import ReviewImage from "./ReviewImage"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { shuffle } from "../../assets/js/helper"
const StaticReview = ({ staticReviews, reviewImages }) => {
  const getRating = rating => {
    switch (rating.value[0].codename) {
      case "n1_star":
        return "★"
      case "n2_stars":
        return "★★"
      case "n3_stars":
        return "★★★"
      case "n4_stars":
        return "★★★★"
      case "n5_stars":
        return "★★★★★"
      default:
        break
    }
  }

  const { rating, review, reviewer, review_source_link, location } = shuffle(
    staticReviews.value
  ).elements

  return (
    <>
      <div className="container singleReview_container">
        <div className="singleReview_info">
          <h2>Customer Reviews</h2>
          <div className="review_quote">
            <RichTextElement value={review.value} />
          </div>
          <div className="review_details">
            <div className="reviewer">
              {reviewer.value}
              <span>&nbsp;</span>
            </div>

            {location.value.length > 0 && (
              <span>{"- " + location.value[0].elements.label.value}</span>
            )}

            <a href={review_source_link.value} target="_blank" rel="noreferrer">
              &nbsp;{"| "}
              Read Review
            </a>
          </div>
          <span className="review_stars">{getRating(rating)}</span>
        </div>
        <ReviewImage image={shuffle(reviewImages.value)} />
      </div>
    </>
  )
}

export default StaticReview
