import React, { useState, useEffect, useContext, useMemo } from "react"
import ReviewImage from "./ReviewImage"
import { shuffle } from "../../assets/js/helper"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { AppContext } from "../context/context"

const SingleReview = ({ reviewImages }) => {
  const data = useStaticQuery(getLogo)
  const [singleReview, setSingleReview] = useState(null)
  const [stars, setStars] = useState("★★★★★")
  const { pestroutesLogo } = data

  const {
    isPRClientAndJQueryScriptsLoaded,
    loadPestRoutesClient,
    handleLoadingPRClientAndJQueryScripts,
  } = useContext(AppContext)

  /* eslint-disable */
  const getPestroutesClientReviews = async () => {
    if (typeof PestroutesClient !== "object") {
      return setTimeout(getPestroutesClientReviews, 100)
    }
    const result = await PestroutesClient.getReviews({
      favorable: 1,
      minRating: 4,
      limit: 15,
      randomize: true,
    })
    //remove reviews containing profanities
    const filteredReviews = result.reviews.filter(
      review => !review.profane && review.feedback.length > 10
    )

    //referenced randomized review to be used in the for loop
    const randomSingleReview = shuffle(filteredReviews)

    let reviewStars = ""
    for (let i = 0; i < randomSingleReview.rating; i++) {
      reviewStars += "★"
    }

    setSingleReview(randomSingleReview)
    setStars(reviewStars)
  }

  /* eslint-disable */
  useEffect(() => {
    let mounted = true

    // avoids loading jquery before the LCP is done
    if (typeof PestroutesClient !== "object") {
      setTimeout(() => {
        if (mounted) {
          loadPestRoutesClient()
          handleLoadingPRClientAndJQueryScripts(true)
        }
      }, 4000)
    } else {
      handleLoadingPRClientAndJQueryScripts(true)
    }

    return () => {
      mounted = false
    }
  }, [])

  /* eslint-disable */
  useEffect(() => {
    if (!isPRClientAndJQueryScriptsLoaded) return
    getPestroutesClientReviews()
  }, [isPRClientAndJQueryScriptsLoaded])
  const randomReviewImage = useMemo(() => shuffle(reviewImages.value), [])
  return (
    <>
      {singleReview && (
        <div className="singleReview_container container">
          <div className="singleReview_info">
            <h2>Customer Reviews</h2>
            <div className="review_quote">{singleReview.feedback}</div>
            <div className="review_details">
              <div>
                <strong>{singleReview.reviewer}</strong>
                <span className="review_details_separator">|</span>
              </div>
              <div>
                {singleReview.city}, {singleReview.state}
                <span className="review_details_separator">|</span>
              </div>
              <div>
                <Link to="/reviews">Read Reviews</Link>
              </div>
            </div>
            <div>
              <div className="review_stars">{stars}</div>
              <div>
                <Img
                  fixed={pestroutesLogo.nodes[0].childImageSharp.fixed}
                  alt="Pest Routes Logo"
                />
              </div>
            </div>
          </div>

          <ReviewImage image={randomReviewImage} />
        </div>
      )}
    </>
  )
}

const getLogo = graphql`
  {
    pestroutesLogo: allFile(
      filter: { relativePath: { eq: "assets/images/pestroutes-logo.png" } }
    ) {
      nodes {
        childImageSharp {
          fixed(quality: 100, width: 152, height: 24) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default SingleReview
