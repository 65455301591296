import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { AppContext } from "../context/context"
import { RiPhoneFill } from "react-icons/ri"
import * as styles from "./secondary-header.module.scss"
import SocialIcons from "../social-icons/SocialIcons"

const SecondaryHeader = ({
  phone,
  showReviews,
  showPortal,
  pestroutesLogo,
  reviewsLogo,
  social,
}) => {
  const { size } = React.useContext(AppContext)

  if (size < 550) {
    return (
      <section className={styles.secondaryHeader}>
        <a href={`tel: ${phone}`} className={styles.secondaryHeaderInner}>
          <RiPhoneFill className={styles.headerPhone} />
          <span className={styles.phoneLink}>{phone}</span>
        </a>
      </section>
    )
  } else if (size < 768) {
    return (
      <section className={styles.secondaryHeader}>
        <div className={styles.socials}>
          {social.value.map(({ elements, id }) => {
            return <SocialIcons key={id} icons={elements} />
          })}
        </div>
        <div className={styles.secondaryHeaderInner}>
          <RiPhoneFill className={styles.headerPhone} />
          <a href={`tel: ${phone}`} className={styles.secondaryHeaderInner}>
            <span className={styles.phoneLink}>{phone}</span>
          </a>
        </div>
      </section>
    )
  } else {
    const prLogoPath = pestroutesLogo.nodes[0].childImageSharp.fixed
    const reviewLogoPath = reviewsLogo.nodes[0].childImageSharp.fixed
    return (
      <section className={styles.secondaryHeader}>
        <div className={styles.socials}>
          {social.value.map(({ elements, id }) => {
            return <SocialIcons key={id} icons={elements} />
          })}
        </div>
        <div className={styles.secondaryHeaderInner}>
          <div className={styles.portalLinks}>
            {showPortal.codename !== "no" && (
              <span className={styles.portalLinkWrapper}>
                <Img
                  fixed={prLogoPath}
                  alt="Pestroutes Logo"
                  className={styles.prLogo}
                />
                <a href="/login" className={styles.portalLink}>
                  Client Login
                </a>
              </span>
            )}
            {showPortal.codename !== "no" && showReviews.codename !== "no" && (
              <span>|</span>
            )}
            {showReviews.codename !== "no" && (
              <span className={styles.portalLinkWrapper}>
                <Img
                  fixed={reviewLogoPath}
                  alt="Reviews Icon"
                  className={styles.reviewsIcon}
                />
                <Link to="/reviews" className={styles.portalLink}>
                  Customer Reviews
                </Link>
              </span>
            )}
          </div>
          {(showPortal.codename === "yes" ||
            showReviews.codename === "yes") && <span>|</span>}
          <RiPhoneFill className={styles.headerPhone} />
          <a href={`tel: ${phone}`} className={styles.phoneLink}>
            <span>{phone}</span>
          </a>
        </div>
      </section>
    )
  }
}

SecondaryHeader.propTypes = {
  phone: PropTypes.string,
}

SecondaryHeader.defaultProps = {
  phone: "(888) 888-8888",
}

export default SecondaryHeader
