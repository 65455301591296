// extracted by mini-css-extract-plugin
export var buyNow = "secondary-header-module--buy-now--2xRpp";
export var headerPhone = "secondary-header-module--header-phone--xkQbZ";
export var phone = "secondary-header-module--phone--1a92M";
export var phoneLink = "secondary-header-module--phone-link--2Yj7z";
export var portalLink = "secondary-header-module--portal-link--1POjr";
export var portalLinkWrapper = "secondary-header-module--portal-link-wrapper--6elY1";
export var portalLinks = "secondary-header-module--portal-links--2jFq0";
export var prLogo = "secondary-header-module--pr-logo--3FUI9";
export var reviewsIcon = "secondary-header-module--reviews-icon--1G8Rz";
export var secondaryHeader = "secondary-header-module--secondary-header--6xMpH";
export var secondaryHeaderInner = "secondary-header-module--secondary-header-inner--6bpM6";
export var smallText = "secondary-header-module--small-text--1dUgX";
export var socials = "secondary-header-module--socials--2pIb8";